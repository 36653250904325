import { SET_AVAYA_EXTENSION } from "../actions/actions";

export default function AvayaExtension(state: string = "", action: any) {
  switch (action.type) {
    case SET_AVAYA_EXTENSION:
      return action.payload;
    default:
      return state;
  }
}
