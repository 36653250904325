const en = {
	bonga_copyright: `Bonga © ${new Date().getFullYear()}`,
	logout: "Logout",
	crm: "CRM",
	dashboard: "Dashboard",
	greeting: "Hello ",
	welcome_back: "Welcome back!",
	stats: "Stats",
	accounts: "{account_title}",
	tickets: "Tickets",
	analytics: "Analytics",
	csat: "CSAT",
	csat_surveys: "CSAT Surveys",
	surveys: "Surveys",
	survey: "Survey",
	section: "Section",
	question: "Question",
	add_survey: "Add Survey",
	edit_survey: "Edit Survey",
	view_survey: "View Survey",
	survey_added_successfully: "Survey added successfully",
	survey_updated_successfully: "Survey updated successfully",
	survey_deleted_successfully: "Survey deleted successfully",
	create_and_manage_surveys: "Create and manage surveys",
	multiple_options: "Multiple Options",
	responses: "Responses",
	questions: "Questions",
	add_question: "Add Question",
	edit_question: "Edit Question",
	view_question: "View Question",
	question_added_successfully: "Question added successfully",
	question_updated_successfully: "Question updated successfully",
	question_rating: "Question Rating",
	add_question_rating: "Add Question Rating",
	add_csat: "Add CSAT",
	position: "Position",
	by_channels: "By Sources",
	help_desk: "Help Desk",
	admin: "Admin",
	crm_analytics: "CRM Analytics",
	chatdesk_analytics: "Chatdesk Analytics",
	chatdesk_overview: "Chatdesk Overview",
	nia_overview: "Nia Overview",
	add_mention: "Add Mention",
	edit_mention: "Edit Mention",
	add_keyword: "Add Keyword",
	edit_keyword: "Edit Keyword",
	hashtags: "Hashtags",
	mentions: "Mentions",
	mention: "Mention",
	keyword: "Keyword",
	overview: "Overview",
	cm_overview: "Call Module Overview",
	live_reports: "Live Report",
	agent_reports: "Agent Reports",
	voice_reports: "Voice Reports",
	reports: "Reports",
	nia_dashboard: "Nia Dashboard",
	add_hashtag: "Add Hashtag",
	edit_hashtag: "Edit Hashtag",
	crm_overview: "CRM Overview",
	account_details: "{account_title} Details",
	ticket_details: "Ticket Details",
	sentiments_overtime: "Sentiments Over time",
	words_frequency: "Words Frequency",
	tweets_overtime: "Tweets Overtime",
	tweets: "Tweets",
	ticket: "Ticket",
	edit_ticket: "Edit Ticket",
	add_ticket: "Add Ticket",
	delete_ticket: "Delete Ticket",
	no_tickets: "No Tickets",
	account: "Account",
	leads: "Leads",
	lead_source: "Lead Source",
	details: "Details",
	ad: "Ad",
	google: "Google",
	faqs: "FAQs",
	employee_referral: "Employee Referral",
	lead_status: "Lead Status",
	lead_type: "Lead Type",
	lead_types: "Lead Types",
	add_lead_type: "Add Lead Type",
	edit_lead_type: "Edit Lead Type",
	delete_lead_type: "Delete Lead Type",
	lead_type_details: "Lead Details",
	lead_type_templates_details: "Lead Type Templates Details",
	lead_types_popover:
		"Create lead types to categorize your leads and add custom fields to each lead type.",
	view_lead_type_access_denied:
		"You do not have access to view Lead Types. Please contact your administrator",
	view_lead_access_denied:
		"You do not have access to view Leads. Please contact your administrator",
	working: "working",
	nurtured: "Nurtured",
	macros: "Macros",
	actions: "Actions",
	converted: "Converted",
	all_contacts: "All Contacts",
	contact_groups: "Contact Groups",
	contacts: "Contacts",
	view_contact: "View Contact",
	view_contact_access_denied:
		"You do not have access to view Contacts. Please contact your administrator",
	add_contact_access_denied:
		"You do not have access to add Contacts. Please contact your administrator",
	active: "Active",
	inactive: "Inactive",
	search: "Search",
	total: "Total",
	ticket_logs: "Ticket Logs",
	new: "New",
	take_break: "Take a break",
	my_breaks: "My Breaks",
	download: "Download",
	download_leads_csv: "Download leads CSV",
	download_leads: "Download leads",
	download_products_CSV: "Download Products CSV",
	download_products: "Download Products",
	pick_up_where_you_left_off: "Pick up where you left off",
	take_a_shortcut_to_tasks: "Take a shortcut to Tasks",
	add_a_task_from_here: "Add a task from here",
	take_a_shortcut_to_adding_events: "Take a shortcut to adding events ",
	add_an_event_from_here: "Add an event from here",
	start_with_something_new: "Start with something new...",
	take_a_shortcut_to_deals_won_overtime:
		"Take a shortcut to deals won overtime",
	check_analysis_from_here: "Check analysis from here",
	check_analysis: "Check Analysis",
	get_started_with_bonga_crm: "Get Started with Bonga CRM",
	create_a_new: "Create a new account,contact,deal or a lead....",
	break: "Take break",
	lead_contact: "Lead Contact",
	select_reason: "Select Reason",
	lunch: "Lunch",
	tea: "Tea",
	health: "Health",
	other: "Other",
	team_chat: "Team Chat",
	cancel: "Cancel",
	save: "Save",
	run: "Run",
	button: "Button",
	agents_online: "Agents Online",
	delete_social: "Delete Social",
	monitor_calls: "Monitor Calls",
	call_module: "Call Module",
	dialer: "Dialer",
	returning: "Returning",
	new_leads: "New Leads",
	returning_leads: "Returning Leads",
	new_contacts: "New Contacts",
	returning_contacts: "Returning Contacts",
	new_accounts: "New {account_title}",
	returning_accounts: "Returning {account_title}",
	add_new_account: "Add New {account_title}",
	add_new_lead: "Add New Lead",
	add_new_contact: "Add New Contact",
	add_new_document: "Add New Document",
	create_document: "Create Document",
	add_new_task: "Add New Task",
	add_new_task_stage: "Add New Task Stage",
	add_new_product: "Add New Product",
	add_new_deal: "Add New Deal",
	edit_deal: "Edit Deal",
	sort_by: "Sort By",
	edit_task: "Edit Task",
	filter_by: "Filter By",
	delete_account: "Delete {account_title}",
	phone_feature_under_development: "Phone Feature Under Development",
	on_development_description:
		"This feature is under development. Please check back later.",
	chat_feature_under_development: "Chat Feature Under Development",
	my_dashboard: "My Dashboard",
	deals_won_overTime: "Deals Won Over Time",
	inbound_outbound: "Inbound/Outbound",
	welcome_to_crm: "Welcome to CRM",
	upgrade_crm_to_access_premium_statistics:
		"Upgrade CRM to access premium statistics",
	products: "Products",
	product: "Product",
	remarks: "Remarks",
	users: "Users",
	notifications: "Notifications",
	no_notifications: "No Notifications",
	no_notifications_description:
		"You have no notifications yet, all your new notifications will appear here",
	no_read_notifications_description:
		"You have no notifications in this category",
	add_product: "Add Product",
	add_lead_status: "Add Lead Status",
	edit_lead: "Edit Lead",
	delete_lead: "Delete Lead",
	add_alt_number: "Add Other Number",
	edit_contact: "Edit Contact",
	delete_contact: "Delete Contact",
	edit_account: "Edit Account",
	view_account_access_denied:
		"You do not have access to view Accounts. Please contact your administrator",
	delete_product: "Delete Product",
	edit_product: "Edit Product",
	view_product_access_denied:
		"You do not have access to view Products. Please contact your administrator",
	edit_lead_status: "Edit Lead Status",
	lead_updated_successfully: "Lead updated successfully",
	add_lead_rating_label: "Add Lead Rating Label",
	edit_lead_rating_label: "Edit Lead Rating Label",
	add_deal_currency: "Add Deal Currency",
	edit_deal_currency: "Edit Deal Currency",
	add_deal_stage_status: "Add Deal Stage Status",
	edit_deal_stage_status: "Edit Deal Stage Status",
	login: "Log In",
	reset_password: "Reset Password",
	enter_email: "Please enter your account email",
	reset_password_description: "Enter your email to reset your password",
	check_email: "Check your email",
	check_email_description:
		"An Email with temporary password will be sent to your inbox. Please reset your password after login.",
	failed_to_send_email: "Failed to send email",
	add_account: "Add {account_title} (optional)",
	event_account_description:
		"Select an {account_title} to associate with this event.",
	add_guests_from_contacts:
		"Invite guests from your contacts and colleagues to attend this event.",
	deals: "Deals",
	types: "Types",
	profile: "Profile",
	settings: "Settings",
	personal_settings: "Personal Settings",
	profile_settings: "Profile Settings",
	manage_password: "Manage Password",
	manage_calls: "Manage Calls",
	manage_crm: "Manage Crm",
	manage_chatdesk: "Manage Chatdesk",
	manage_profile: "Manage Profile",
	manage_business_rules: "Manage Business Rules",
	manage_org_details: "Manage Organization Details",
	manage_branches: "Manage Branches",
	manage_users: "Manage Users",
	manage_roles: "Manage Roles",
	manage_role: "Manage Role",
	manage: "Manage {item}",
	feature: "Feature",
	view: "View",
	change: "Change",
	create: "Create",
	roles: "Roles",
	assign_user_role: "Assign User Role",
	user_roles_assigned_successfully: "User roles assigned successfully",
	user_roles: "User Roles",
	permissions: "Permissions",
	click_to_edit: "Click to Edit Date and Time",
	time: "Time",
	week: "Week",
	calendar: "Calendar",
	contact: "Contact",
	next_week: "Next Week",
	previous_week: "Previous Week",
	event: "Event",
	day: "Day",
	add_event: "Add Event",
	edit_event: "Edit Event",
	add_event_description: "Add Event Description",
	add_event_title: "Add Event Title",
	add_guests: "Add Guests",
	end_of_scroll: "No more results... 📃",
	start_of_scroll: "Looking for tickets... 🔍",
	summary: "Summary",
	reply_with_macro: "Reply with Macro",
	reply_with: "Reply with",
	add_category: "Add Category",
	view_category: "View Category",
	edit_category: "Edit Category",
	delete_category: "Delete Category",
	manage_categories_access_denied:
		"You do not have access to manage categories",
	status: "Status",
	statuses: "Statuses",
	role: "Role",
	add_role: "Add Role",
	edit_role: "Edit Role",
	view_role: "View Role",
	delete_role: "Delete Role",
	role_added_successfully: "Role added successfully",
	updated_successfully: "Updated successfully",
	add_status: "Add statuses",
	view_status: "View statuses",
	edit_status: "Edit statuses",
	delete_status: "Delete statuses",
	add_to_list: "Add to List",
	edit_date: "Edit Date",
	channels: "Channels",
	add_channel: "Add channel",
	view_channel: "View channel",
	edit_channel: "Edit channel",
	delete_channel: "Delete channel",
	upload_file: "Upload File",
	upload_description:
		"For best results, please upload a 16bit 8khz/16khz mono WAV file.",
	date_created: "Date Created",
	click_or_drag_file_to_this_area_to_upload:
		"Click or drag file to this area to upload",
	click_to_add_file: "Click to add file",
	priorities: "Priorities",
	add_priority: "Add priorities",
	view_priority: "View priorities",
	edit_priority: "Edit priorities",
	delete_priority: "Delete priorities",
	services: "Services",
	add_service: "Add service",
	view_service: "View service",
	edit_service: "Edit service",
	delete_service: "Delete service",
	clients: "Clients",
	add_client: "Add client",
	view_client: "View client",
	edit_client: "Edit client",
	add_socials: "Add Socials",
	socials: "Socials",
	add_social: "Add_social",
	social_handle: "Social handle",
	handle: "handle",
	display_name: "Display name",
	delete_client: "Delete client",
	agents: "Agents",
	platform: "Platform",
	to: "To",
	adding_social: "Adding Social Handle",
	from: "From",
	sending: "Sending...",
	email_placeholder: "Write your email here",
	add_label: "Add label",
	add_new_label: "Add new label",
	add_agent: "Add agent",
	view_agent: "View agent",
	edit_agent: "Edit agent",
	delete_agent: "Delete agent",
	view_macros: "View Macros",
	edit_macros: "Edit Macros",
	delete_macros: "Delete Macros",
	delete_confirm: "Are you sure you want to delete this ",
	delete_success: " Item deleted successfully",
	delete_error: "Error deleting item",
	assign_agent: "Assign Agent",
	assign_agent_role: "Assign Agent Role",
	add_macros: "Add Macros",
	add_macro: "Add Macro",
	view_macro: "View Macro",
	edit_macro: "Edit Macro",
	delete_macro: "Delete Macro",
	message_template: "Template",
	add_message_template: "Add Template",
	edit_message_template: "Edit Template",
	new_template_body_placeholder: "Message body goes here",
	new_template_header_placeholder: "Header text goes here",
	new_template_footer_placeholder: "Footer text goes here",
	apps: "Apps",
	send_email: "Send Email",
	inbox: "Inbox",
	sent: "Sent",
	close: "Close",
	sync_with_google: "Sync with Google",
	use_without_google: "Use Without Google",
	account_not_synced: "Account not synced",
	account_not_synced_description:
		"Your account is not synced with Google. Please sync your account to access your emails, events and contacts.",
	gmail_auth_response: "token does not exist please authenticate",
	drafts: "Drafts",
	trash: "Trash",
	compose: "Compose",
	update: "Update",
	not_found:
		"This page doesn't seem to exist!.\n Please check the URL and try again.",
	page_moved: "Page Moved",
	page_moved_description:
		"This page has been moved to a new location. Please click the button below to go to the new page.",
	go_to_new_page: "Go to new page",
	latest: "Latest",
	back: "Back",
	back_home: "Back Home",
	update_success: "Item Updated Successfully",
	update_error: "Error Updating Item",
	links: "Links",
	linked_event: "Linked Event",
	linked_deal: "Linked Deal",
	linked_lead: "Linked Lead",
	add_links: "Add Links",
	view_action: "View Action",
	edit_action: "Edit Action",
	delete_action: "Delete Action",
	add_action: "Add Action",
	action_type: "Action Type",
	update_action_success: "Action updated successfully",
	add_action_success: "Action added successfully",
	view_action_access_denied:
		"You do not have access to view Actions. Please contact your administrator",
	add_faq: "Add FAQ",
	view_faq: "View FAQ",
	edit_faq: "Edit FAQ",
	hashtag: "Hashtag",
	delete_faq: "Delete FAQ",
	delete: "Delete",
	action: "Action",
	macro: "Macro",
	faq: "FAQ",
	client: "Client",
	agent: "Agent",
	priority: "Priority",
	low: "Low",
	high: "High",
	urgent: "Urgent",
	service: "Service",
	channel: "Channel",
	category: "Category",
	categories: "Categories",
	delete_description: "Are you sure you want to delete this item?",
	delete_disclaimer: "This action cannot be undone.",
	signup: "Sign Up",
	login_text:
		"Welcome back to Bonga, Log in to your account to continue using our services",
	signup_text: "Welcome to Bonga, create an account to access our services",
	forgot_password: "Forgot Password?",
	already_a_user: "Have an account?",
	account_name: "{account_title} Name",
	account_type: "{account_title} Type",
	rating: "Rating",
	rating_icon: "Rating icon",
	content: "Content",
	macro1: "Macro 1",
	macro2: "Macro2",
	available_for: "Available for",
	all_agents: "All Agents",
	me_only: "Me Only",
	agents_in_group: "Agent in group",
	no_of_employees: "No. of Employees",
	description: "Description",

	organization: "Organization",
	account_owner: "{account_title} Owner",
	mobile: "Mobile",
	password: "Password",
	confirm_password: "Confirm Password",
	old_password: "Old Password",
	new_password: "New Password",
	enter_new_password: "Enter New Password",
	enter_old_password: "Enter Old Password",
	update_password: "Update Password",
	password_dosent_match: "Password doesn't match",
	password_dosent_match_description:
		"The passwords you entered doesn't match. Please confirm password to continue.",
	contact_name: "Contact Name",
	title: "Title",
	repeat: "Repeat",
	sub_tasks: "Sub Tasks",
	add_sub_task: "Add Sub Task",
	edit_sub_task: "Edit Sub Task",
	first_name: "First Name",
	last_name: "Last Name",
	company: "Company",
	email: "Email",
	extension: "Extension",
	username: "Username",
	add_user: "Add User",
	edit_user: "Edit User",
	deactivate_user: "Deactivate User",
	activate_user: "Activate User",
	deactivate_user_description: "Are you sure you want to deactivate this user?",
	activate_user_description: "Are you sure you want to activate this user?",
	delete_user: "Delete User",
	delete_user_description: "Are you sure you want to delete this user?",
	view_user: "View User",
	name: "Name",
	option: "Option",
	question_options: "Question Options",
	required: "Required",
	todo: "Todo",
	in_progress: "In Progress",
	done: "Done",
	add: "Add",
	group_name: "Group Name",
	select_contacts_below: "Select contacts below",
	add_group: "Add Group",
	group_added_successfully: "Group added successfully",
	group_updated_successfully: "Group updated successfully",
	group_deleted_successfully: "Group deleted successfully",
	group_delete_confirmation: "Are you sure you want to delete this group?",
	group_delete_disclaimer: "This action cannot be undone.",
	group_delete_error: "Error deleting group",
	change_password: "Change Password",
	type: "Type",
	price: "Price",
	phone_number: "Phone Number",
	enter_phone_numbers: "Enter Phone Numbers",
	lead_owner: "Lead Owner",
	department: "Department",
	add_department: "Add Department",
	edit_department: "Edit Department",
	updated_department_successfully: "Department updates successfully",
	error_adding_department: "Error adding department",
	added_department_successfully: "Added department successfully",
	select_branch: "Select Branch",
	error_branch_required: "Branch is Required",
	department_details: "Department Details",
	created_by: "Created By",
	product_owner: "Created By",
	product_code: "Product Code",
	valid_till: "Valid Till",
	subject: "Subject",
	due_date: "Due Date",
	closed_by: "Closed By",
	currency: "Currency",
	product_type: "Product Type",
	product_category: "Product Category",
	add_product_type: "Add Product Type",
	edit_product_type: "Edit Product Type",
	add_product_category: "Add Product Category",
	edit_product_category: "Edit Product Category",
	category_name: "Category Name",
	product_type_name: "Product Type Name",
	success: "Success",
	product_type_updated_successfully: "Product Type updated successfully",
	product_category_updated_successfully:
		"Product Category updated successfully",
	queue_added_successfuly: "Queue has been added Successfully",
	menu_added_successfully: " IVR Menu added successfully",
	menu_option_added_successfully: "IVR Menu Option added successfully",
	add_menu_option: "Add Menu Option",
	ivr_menu: "IVR Menu",
	edit_menu: "Edit IVR Menu",
	delete_menu: "Delete IVR Menu",
	recording_added_successfuly: "Recording has been added Successfully",
	moh_added_successfully: " Music on hold added successfully",
	moh: "Music on Hold",
	add_moh: "Add Music on Hold",
	edit_moh: "Edit Music on Hold",
	delete_moh: "Delete Music on Hold",
	call_settings: "Call Configuration Settings",
	destinations: "Destinations",
	destination_added_successfully: " Destinations added successfully",
	add_destination: "Add Destination",
	edit_destination: "Edit Destination",
	delete_destination: "Delete Destination",
	please_upload_file: "Please upload a file",
	upload_audio_file: "Upload Audio File",
	close_date: "Close Date",
	loss_reason: "Loss Reason",
	label: "Label",
	link_a_lead: "Link a Lead",
	link_a_contact: "Link a Contact",
	link_a_deal: "Link a Deal",
	link_a_product: "Link a Product",
	link_a_ticket: "Link a Ticket",
	link_an_event: "Link an Event",
	stage: "Stage",
	stage_status: "Stage Status",
	delete_task: "Delete Task",
	amount: "Amount",
	assigned_to: "Assigned To",
	no_accounts: "{account_title} will appear here once you create one",
	no_calls: "Recent calls will appear here once you make one",
	recent_calls: "Recent Calls",
	inbound_calls: "Inbound Calls",
	inbound: "Inbound",
	outbound: "Outbound",
	inbound_calls_by_day: "Inbound Calls By Day",
	inbound_overview: "Inbound Overview",
	outbound_overview: "Outbound Overview",
	inbound_calls_received_by_agents: "Inbound Calls received by Agents",
	outbound_calls_received_by_agents: "Outbound Calls received by Agents",
	inbound_calls_per_hour: "Inbound Calls Per Hour",
	outbound_calls_per_hour: "Outbound Calls Per Hour",
	inbound_calls_by_status: "Inbound Calls By Status",
	outbound_calls_by_status: "Outbound Calls By Status",
	calls_by_day: "Calls By Day",
	calls_by_agents: "Calls By Agents",
	calls_per_hour: "Calls Per Hour",
	to_do: "To-do",
	add_task: "Add Task",
	incomplete_tasks: "Incomplete Tasks",
	completed_taskks: "Completed Tasks",
	view_task_access_denied:
		"You do not have access to view Tasks. Please contact your administrator",
	inbound_calls_by_date: "Inbound Calls By Date",
	deals_created_per_month: "Deals Created Per Month",
	leads_converted_per_month: "Leads Converted Per Month",
	inbound_by_month_and_call_status: "Inbound by Month and Call Status",
	outbound_by_month_and_call_status: "Outbound by Month and Call Status",
	inbound_outbound_agents: "Inbound And Outbound Call stats by Agents",
	talk_ring_cost_agents: "Average Time Stats By Agents",
	user_stats: "User Stats",
	nia_stats: "Nia Stats",
	overall_analytics: "Overall Analytics",
	ticketing_video:
		"Here is a video to help navigate through our Ticketing system!",
	crm_video: "Here is a video to help navigate through our CRM system!",
	nia_video: "Here is a video to help navigate through Nia!",
	call_video: "Here is a video to help navigate through our Call Module!",
	tweet_impression: "Tweet impressions by month",
	tweets_impression_overtime: "Realtime update of tweet impression",
	tweet_engagement: "Tweet engagement Analysis",
	total_agents: "Total No of Agents",
	top_callmaker: "Top Call Maker",
	top_callreceiver: "Top Call Receiver",
	agent_performance: "Agent Performance",
	total_outbound_calls: "Total Outbound Calls",
	total_callcost: "Total Call Cost",
	total_inbound_calls: "Total Inbound Calls",
	unresolved_tickets: "Unresolved Tickets",
	my_tickets_by_status: "My Tickets by Status",
	tickets_escalated: "Tickets Escalated to You",
	export: "Export",
	outbound_calls_by_date: "Outbound Calls By Date",
	outbound_calls: "Outbound Calls",
	account_leads: "{account_title} Leads",
	account_contacts: "{account_title} Contacts",
	account_deals: "{account_title} Deals",
	account_tickets: "{account_title} Tickets",
	leads_generated_by_month: "Leads generated by month",
	graph_one_title: "Ticket Status per Account",
	graph_two_title: "Average tickets created by weekday",
	graph_three_title: "Tickets Pipeline by Date",
	pipeline_trend: "Pipeline trend",
	graph_four_title: "By Ticket Status",
	customer_satisfaction: "Customer Satisfaction",
	tickets_subject: "Tickets Subject",
	select_client: "Select Client",
	select_contact: "Select Contact",
	select_agent: "Select Agent",
	select_agents: "Select Agents",
	select_category: "Select Category",
	select_priority: "Select Priority",
	select_status: "Select Status",
	select_type: "Select Type",
	select_channel: "Select Channel",
	select_service: "Select Service",
	select_macro: "Select Macro",
	select_action: "Select Action",
	select_source: "Select Source",
	select_account: "Select Account",
	select_tags: "Select Tags",
	ticket_description: "Ticket Description",
	search_agent_group: "Search Agent Group",
	chat: "Chat",
	client_details: "Client Details",
	lorem_ipsum:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl nec aliquam aliquam, nunc nisl aliquam nisl.",
	via_twitter: "via Twitter",
	via: "via",
	twitter: "Twitter",
	facebook: "Facebook",
	linkedin: "Linkedin",
	whatsapp: "WhatsApp",
	templates: "Whatsapp Message Templates",
	Messenger: "Messenger",
	sms: "SMS",
	smss: "SMSs",
	enter_sms: "Please enter your SMS message here",
	telegram: "Telegram",
	preferred: "Preferred",
	client_account: "Client {account_title}",
	account_products: "{account_title} Products",
	add_to_account: "Add to {account_title}",
	overdue: "Overdue",
	open: "Open",
	negative: "Negative",
	sentiment: "Sentiment",
	location: "Location",
	industry: "Industry",
	activity_log: "Activity Log",
	no_logs: "No logs found",
	no_logs_description:
		"User's activity will appear here once they perform an action",
	ticket_created: "Ticket Created",
	conversation_with: "Conversation with ",
	email_from: "Email from ",
	via_email: "via Email",
	agent_replied: "Agent replied to",
	ticket_closed: "Ticket Closed",
	ticket_reopened: "Ticket Reopened",
	ticket_assigned: "Ticket Assigned",
	agents_assigned: "Agents Assigned",
	load_more: "Load More",
	see_all: "See All",
	owner: "Owner",
	employees: "Employees",
	events: "Events",
	files: "Files",
	integration: "Integration",
	integrations: "Integrations",
	add_integration: "Add Integration",
	edit_integration: "Edit Integration",
	tag: "Tag",
	tags: "Tags",
	add_tag: "Add Tag",
	view_tag: "View Tag",
	edit_tag: "Edit Tag",
	delete_tag: "Delete Tag",
	emails: "Emails",
	notes: "Notes",
	sources: "Sources",
	tasks: "Tasks",
	reminders: "Reminders",
	reminders_description: "View and manage your reminders",
	add_reminder: "Add Reminder",
	view_reminder: "View Reminder",
	edit_reminder: "Edit Reminder",
	delete_reminder: "Delete Reminder",
	documents: "Documents",
	document_editor: "Document Editor",
	download_document: "Download Document",
	create_and_manage_documents: "Create and Manage Documents",
	send: "Send",
	message: "Message",
	proceed: "Proceed",
	loading: "Loading...",
	see_details: "See Details",
	macro_details: "Macro Details",
	close_details: "Close Details",
	write_message_here: "Write message here...",
	lead_rating: "Lead Rating",
	lead: "Lead",
	deal: "Deal",
	edit: "Edit",
	lead_admin: "Lead Admin",
	no_tweets: "No Tweets",
	closed_on: "Closed On",
	get_started: "Get Started",
	get_started_for_free: "Get Started For Free",
	already_setup: "Already have an account?",
	org_name: "Business Name",
	org_email: "Business Email",
	add_your_org: "Add your Business",
	free_plan: "Signup for the Free Basic Plan",
	info: "Info",
	phone: "Phone",
	accept_call: "Accept Call",
	decline_call: "Decline Call",
	outgoing: "Outgoing",
	incoming: "Incoming",
	business_login: "Use your agent credentials to login",
	free_plan_desc:
		"You will be able to access full featured modules for free, and only pay for premium modules and features as needed.",
	add_your_org_description: "Add your business to get started",
	dont_have_an_account: "Don't have an account?",
	link_ticket_to_account: "Are you sure you want to link this ticket to ",
	connect_phone: "Connect Phone",
	disconnect_phone: "Disconnect Phone",
	leads_statistics_by_month: "Leads statistics by month",
	upload_contacts: "Upload Contacts",
	upload_document: "Upload Document",
	clear_upload: "Clear Upload",
	download_contacts: "Download Contacts",
	download_accounts_CSV: "Download {account_title} CSV",
	download_leads_CSV: "Download Leads CSV",
	download_contacts_csv: "Download Contacts CSV",
	download_template: "Download Template",
	contact_details: "Contact Details",
	download_events: "Download Events",
	upload_products: "Upload Products",
	download_products_template: "Download Products Template",
	download_deals_csv: "Download Deals CSV",
	download_tasks: "Download Tasks",
	download_users: "Download Users",
	download_contacts_template: "Download Contacts Template",
	upload_leads: "Upload Leads",
	download_leads_template: "Download Leads Template",
	download_deals: "Download Deals",
	shortcuts: "Shortcuts",
	source: "Source",
	clear_filters: "Clear Filters",
	no_results_to_show: "No results to show",
	event_error: "Cannot Create/Edit an Event in the Past",
	team: "Team",
	teams: "Teams",
	add_team: "Add Team",
	edit_team: "Edit Team",
	ticket_lifetime: "Ticket lifetime in minutes",
	manage_dids: "Manage DIDs",
	manage_exts: "Manage Extensions",
	recharge_did: "Recharge DID",
	view_did: "View DID Details",
	request_a_did: "Request a DID",
	request_an_ext: "Request an Extension(s)",
	queue: "Queue",
	add_queue: "Add Queue",
	edit_queue: "Edit Queue",
	delete_queue: "Delete Queue",
	queue_type: "Queue Type",
	ring_strategy: "Ring Strategy",
	add_ivr_menu: "Add IVR Menu",
	add_recording: "Add Recording",
	add_exts_to_queue: "Add Extensions to Queue",
	error: "Error",
	delete_card: "Delete Card",
	add_card: "Add Card",
	add_payment_method: "Add Payment Method",
	set_as_default: "Set as Default",
	payment_methods: "Payment Methods",
	payment_methods_description: "Manage your payment methods",
	something_went_wrong: "Something went wrong",
	add_option_destination: "Add Option Destination",
	remove_option_destination: "Remove Option Destination",
	select_recording: "Please Select Recording",
	select_queue: " Please Select Queue",
	ivr_menu_options: "IVR Menu Options",
	add_ivr_menu_option: "Add Option",
	add_option: "Add Option",
	select_menu_type: "Please Select Menu Type",
	table_view: "Table View",
	board_view: "Board View",
	recording: "Recording",
	no_recording_found: "No recording found",
	task_stage: "Task Stage",
	updated_task_stage_successfully: "Updated task stage successfully",
	edit_task_stage: "Edit Task Stage",
	add_option_Destination: "Add Option Destination",
	remove_option: "Remove Option",
	entry: "Entry",
	entries: "Entries",
	comment: "Comment",
	comments: "Comments",
	reply: "Reply",
	replies: "Replies",
	account_title: "Account",
	accounts_title: "Accounts",
	forms: "{form_title}",
	add_new_form: "Add New {form_title}",
	field: "Field",
	field_type: "Field Type",
	add_field: "Add {form_title} Field",
	edit_field: "Edit {form_title} Field",
	delete_field: "Delete {form_title} Field",
	custom_fields: "{field} {number}",
	text: "Text",
	textarea: "Textarea",
	checkbox: "Checkbox",
	radio: "Radio",
	select: "Select",
	number: "Number",
	date: "Date",
	website: "Website",
	select_template_form: "Select Template {form_title}",
	linked_task: "Linked Task",
	link_ticket_to_task: "Link Ticket to Task",
	no_linked_task: "No task is linked to this ticket",
	tasks_without_stage: "Tasks Without an Allocated Stage",
	by_agent: "Ticket Status per Agent",
	add_account_type: `Add {account_title} Type`,
	edit_account_type: `Edit {account_title} Type`,
	convert_lead_to_account: `Convert Lead to {account_title}`,
	confirm_convert: `Are you sure you want to convert this Lead to {account_title}`,
	funnel: "Funnel",
	add_new: "Add New ",
	no_contact: "No contact linked",
	my_report: "My Report",
	overall_report: "Overall Report",
	preview: "Preview",
	view_comments: "View Comments",
	leads_without_status: "Leads Without a Status",
	leads_converted_to_account: "Leads Converted to {account_title}",
	lead_already_converted: "Lead Already Converted",
	disposition: "Disposition",
	call_disposition: "Call Disposition",
	create_form: "Create {form_title}",
	create_ticket: "Create Ticket",
	create_task: "Create Task",
	create_event: "Create Event",
	create_deal: "Create Deal",
	create_lead: "Create Lead",
	add_contact: "Add New Contact",
	my_inbound_report: "My Inbound Calls",
	my_outbound_report: "My Outbound Calls",
	my_missed_report: "My Missed Calls",
	overall_answered_outbound: "Answered Outbound",
	overall_answered_inbound: "Answered Inbound",
	overall_answered_total: "Answered Total",
	overall_inbound: "Overall Inbound",
	overall_outbound: "Overall Outbound",
	overall_missed_inbound: "Missed Inbound",
	overall_missed_outbound: "Missed Outbound",
	overall_missed_total: "Missed Total",
	overall_abandoned_total: "Abandoned Total",
	overall_abandoned_inbound: "Abandoned Inbound",
	overall_abandoned_outbound: "Abandoned Outbound",
	activate: "Activate",
	deactivate: "Deactivate",
	activate_account: "Activate {account_title}",
	deactivate_account: "Deactivate {account_title}",
	deactivated_account_action:
		"Actions Available are Activation and Deletion Only",
	confirm_activation: "Are you sure you want to activate",
	confirm_deactivation: "Are you sure you want to deactivate",
	live_calls: "Live Calls",
	reload: "Reload",
	soft_phone: "Soft Phone",
	whisper: "Whisper",
	spy: "Spy",
	transfer: "Transfer",
	transfer_to_survey: "Transfer to Survey",
	transfer_call: "Transfer Call",
	return_to_call: "Return to call",
	view_transactions: "View Transactions",
	hold: "Hold",
	resume: "Resume",
	merge: "Merge",
	barge: "Barge",
	make_call: "Make Call",
	call: "Call {number}",
	access_denied: "Access Denied",
	access_denied_message: "You do not have permission to access this feature",
	make_call_to: "Please confirm making a call to {number}",
	alt_phone_number: "Alternative Phone Number",
	connected: "Connected",
	disconnected: "Disconnected",
	connected_message: "Your phone is online and can receive and make calls",
	call_on_hold: "On Hold",
	call_on_hold_description: "Your Call is on Hold...",
	disconnected_message:
		"Your phone is offline and cannot receive or make calls",
	account_forms_empty: "No {form_title} available for this {account_title}",
	form_details: "{form_title} Details",
	template_details: "Template Details",
	currently_on_break: "You are currently on a break!",
	taken_break: "You have successfully taken a break",
	failed_break: "Unable to take break",
	resumed_from_break: "You have successfully resumed from break",
	failed_resume_from_break: "Unable to resume from break",
	unread: "Unread",
	view_details: "View Details",
	view_activity_logs:
		"View all activity logs and filter them based on different fields",
	accounts_popover:
		"{account_title} that have been created or converted from leads",
	account_details_popover:
		"Manage selected {account_title} details such as events, deals, tasks, documents, etc.",
	account_details_popover_leftbar:
		"View {account_title} details and also ability to edit, deactivate, or delete it",
	account_definition:
		"An {account_title} is a business/organization/individual that a sales team is working with or targeting as a potential customer. They are associated with one or more contacts who can be contacted by the sales team",
	study_definition:
		"A {account_title} is a specific research project or survey that is being conducted in the field. Studies may be used to collect information about customer preferences, market trends, or other factors that can help inform the sales strategy of the business",
	crm_funnel_details: "Various functionalities such as leads management",
	crm_analytics_details: "Analytics for leads, deals, agent statistics etc",
	crm_admin_details:
		"Administrator functionalities for managing the CRM, including lead statuses, rating, deal currencies and statuses, products, task stages, form templates, account types, etc.",
	crm_overview_details: "Overview for the CRM, with quick actions",
	lead_details:
		"A lead is a company/individual who has expressed interest in the company's products and/or services",
	action_details: "An action triggers an automated action",
	deal_details:
		"A deal is a potential sale that is being actively pursued by the sales team. Deals are associated with a specific {account_title}",
	product_details:
		"A product is a specific item or service that a business sells to its customers",
	task_details:
		"A task is a specific action or activity that needs to be completed by a member of the team. Tasks can be associated with events, leads, deals, etc.",
	form_definition:
		"A {form_title} is a digital document or template that is used to collect and store information about leads, customers, deals, etc. Forms can help standardize data collection and ensure that key information is captured consistently across different members of the team",
	lead_status_def:
		"A lead status is the stage of the sales process that a particular lead has reached. Common lead statuses include 'new', 'contacted', 'qualified', 'proposal sent', 'closed won', and 'closed lost'",
	lead_rating_def:
		"A lead rating (also known as lead scoring) is a numerical value or score given to a lead based on their level of engagement and interest in the company's products or services",
	deal_currency_def:
		"A deal currency is the currency in which a sales deal or opportunity is denominated. When a sales team is working with customers or leads located in different countries or regions, it may be necessary to use different currencies to price and track the value of the deals",
	deal_stage_status_def:
		"A deal stage status is the stage of a sales opportunity or deal in the sales pipeline. The deal stage status provides information about the progress of the sales team in moving the opportunity towards a closed sale. Examples include 'new', 'in discussion', 'won', 'lost'",
	product_category_def:
		"A product category is a grouping or classification of related products or services. Product categories organize and group together products that share similar characteristics, features, or target markets",
	product_type_def:
		"A product type is a specific type or variation of a product within a larger product category. Product types are used to further differentiate individual products within a category based on specific characteristics or features",
	task_stage_def:
		"A task stage is the stage of a task in a larger workflow or process. Task stages are typically used to track progress and provide visibility into the status of a particular task. Examples include 'To Do', 'Planning', 'In Progress', 'Completed'",
	task_label_def:
		"A task label is a tag or identifier that is assigned to a specific task. Task labels are typically used to categorize tasks or activities based on their type, priority, or status",

	forms_template: "Create templates and define fields here",
	accounts_template: "Create types here",
	value: "Value",
	dtmf_option: "DTMF Option",
	all: "All",
	read: "Read",
	assigned_to_me: "Assigned to me",
	branch: "Branch",
	branches: "Branches",
	branch_details:
		"A branch typically refers to a separate unit or division within the organization that is responsible for carrying out specific functions or operations",
	add_branch: "Add Branch",
	added_branch_successfully: "Branch added successfully",
	error_adding_branch: "Could not add branch",
	updated_branch_successfully: "Updated branch successfully",
	view_branch_access_denied:
		"You do not have access to view your organization branches. Please contact your administrator",
	error_updating_branch: "Could not update branch",
	edit_branch: "Edit Branch",
	mentioned_you_in: "mentioned you in",
	assigned_you_to: "assigned you to",
	task: "task",
	document: "document",
	call_agent: "{action} {agent}",
	revenue_by_deal_currency: "Revenue by Deal Currency",
	belongs_to: "Belongs to:",
	me: "Me",
	deals_without_stage: "Deals without a stage",
	enter_valid_number: "Please enter a valid number",
	next: "Next",
	previous: "Prev",
	file_does_not_exist: "Oops! The requested file does not exist",
	labels: "Labels",
	auto_dialer: "Auto-Dialer",
	add_auto_dialer: "Add Auto-Dialer",
	robot_call: "Robot-Call",
	add_robot_call: "Add Robot Call",
	add_campaign: "Add Campaign",
	view_campaign: "View Campaign",
	campaign_stats: "Campaign Stats",
	campaign_name: "Campaign : {campaign_name}",
	campaign_contacts: "Campaign Contacts",
	view_campaign_access_denied:
		"You do not have access to view Campaigns, Please contact your administrator",
	target: "Target",
	target_type: "Target Type",
	frequency: "Frequency",
	once: "Once",
	daily: "Daily",
	weekly: "Weekly",
	monthly: "Monthly",
	quarterly: "Quarterly",
	bi_anually: "Bi-Anually",
	yearly: "Yearly",
	recipients: "Recipients",
	campaigns: "Campaigns",
	contact_not_found: "Oops, contact not found",
	send_sms: "Send SMS",
	filters: "Filters",
	start_date: "Start Date",
	end_date: "End Date",
	template: "Template",
	template_name: "Template Name",
	save_template: "Save Template",
	discard: "Discard",
	webchat: "Web Chat",
	mobilechat: "Mobile Chat",
	api_key: "Api Key",
	copy_api_key: "Copy Api Key",
	webchat_def:
		"A webchat is a communication tool that allows your website visitors to interact your agents, or enable them to create tickets. It is in the form of a pop-up window with chat capabilities that appears on the website and allows visitors to send messages and receive replies from customer support agents",
	to_use_this_webchat:
		"To use the webchat on your website, place this code within the <body> at the root of your application, most likely in the index.html file, or in a specific page you need the webchat",
	also_add_this_webchat:
		"Then copy this code and place it after closing your <body>. The webchat should then be ready to provide live chat capabilities with your agents on Chatdesk, and also the ability of your website visitors to create tickets",
	connect_to_livechat: "Connect to Live Chat",
	disconnect_from_livechat: "Disconnect from Live Chat",
	sla: "SLA",
	slas: "SLAs",
	add_sla: "Add SLA",
	edit_sla: "Edit SLA",
	time_disclaimer: "All times are in minutes",
	first_response_time_def:
		"The first response time is the time elapsed between when a ticket is created and the time the agent provides the first response or marks the ticket as 'In Progress'",
	first_response_time: "First response time",
	handling_time: "Handling time",
	handling_time_def:
		"The handling time is the time between an agent's first reply / when a ticket is marked as 'In Progress' and the time the ticket is marked as closed",
	resolution_time: "Resolution time",
	resolution_time_def:
		"The resolution time is the total time between creation of a ticket and it being marked as closed",
	holiday: "Holiday",
	holidays: "Holidays",
	add_holiday: "Add Holiday",
	edit_holiday: "Edit Holiday",
	business_hours: "Business Hours",
	no_business_hours_set: "No business hours have been set up",
	setup: "Setup",
	verified: "Verified",
	copy_number: "Copy Number",
	all_numbers: "All Numbers",
	add_detail: "Add {detail}",
	edit_detail: "Edit {detail}",
	timeline: "Timeline",
	ticket_count: "Ticket Count",
	escalation_agents: "Agents to handle escalations for this SLA",
	first_response_time_breached: "First Response Time SLA Breached",
	handling_time_breached: "Handling Time SLA Breached",
	resolution_time_breached: "Resolution Time SLA Breached",
	ticket_categories_times: "By Ticket Categories Scores/SLAs",
	data_by_categories: "Data by Categories",
	data_by_services: "Data by Services",
	data_by_sources: "Data by Sources",
	ticket_categories_statuses: "Statuses by Categories",
	ticket_categories_priorities: "Priorities by Categories",
	ticket_categories_sources: "Sources by Categories",
	ticket_services_statuses: "Statuses by Services",
	ticket_services_priorities: "Priorities by Services",
	ticket_services_sources: "Sources by Services",
	ticket_sources_statuses: "Statuses by Sources",
	ticket_sources_priorities: "Priorities by Sources",
	chatdesk_analytics_access_denied:
		"You do not have access to Chatdesk Analytics, Please contact your administrator",
	manage_chatdesk_customizations_access_denied:
		"You do not have access to manage Chatdesk Customizations, Please contact your administrator",
	by_category: "By Ticket Category",
	ticket_agents_times: "Agents Metrics",
	download_ticket: "Download Ticket",
	subscriptions: "Subscriptions",
	subscription_preferences: "Preferences",
	subscription_history: "Subscription History",
	subscription_invoices: "Subscription Invoices",
	subscription_invoices_description:
		"View and manage your subscription invoices",
	subscription_plans: "Subscription Plans",
	subscription_plans_description: "Upgrade to a different subscription plans",
	subscribed_plans: "Subscribed Plans",
	subscribed_plans_description: "View and manage your subscription plans",
	subscription_seats: "Subscription Seats",
	wallets: "Wallets",
	wallet_transactions: "Wallet Transactions",
	purchase: "Purchase",
	seats: "Seats",
	wallets_description: "View and manage your wallets",
	main_wallet: "Main Wallet",
	branch_wallet: "Branch Wallet",
	add_wallet: "Add Wallet",
	edit_wallet: "Edit Wallet",
	delete_wallet: "Delete Wallet",
	top_ups: "Top Ups",
	top_up_wallet: "Top Up Wallet",
	top_up_org_wallet: "Top Up Your Organisation Wallet",
	top_up_org_wallet_description:
		"Top up your organisation wallet to enable you to purchase premium features",
	choose_payment_method: "Choose Payment Method",
	redeemed_units: "Redeemed Units",
	redeemed_units_description: "View and manage your redeemed units",
	address_line_1: "Address Line 1",
	address_line_2: "Address Line 2",
	city: "City",
	state: "State",
	postal_code: "Postal Code",
	country: "Country",
	company_name: "Company Name",
	ticketing: "Ticketing",
	avg_sentiment_score: "Sentiment Score",
	avg_first_response_time: "First Response Time (Min)",
	avg_handling_time: "Handling Time (Min)",
	avg_resolution_time: "Resolution Time (Min)",
	avg_agent_csat_rating: "Agent CSAT Rating",
	avg_company_csat_rating: "Company CSAT Rating",
	avg_nps_score: "NPS Score",
	tickets_resolved: "Client Marked as Resolved",
	tickets_unresolved: "Client Marked as Unresolved",
	ticket_actions: "Available Ticket Actions",
	change_status: "Change Status",
	business_rules: "Business Rules",
	customizations: "Customizations",
	yes: "Yes",
	no: "No",
	google_business_messaging: "Google Business Messaging",
	configurations_saved: "Configurations saved successfully",
	ticket_auto_assignment: "Ticket Auto-Assignment",
	out_of_office_response: "Out-of-Office Response",
	out_of_office_response_placeholder:
		"Enter your out_of_office response that is automatically sent outside business hours",
	ticket_sharing_link: "Share a Ticket-Tracking Link with Clients",
	ticket_sharing_link_sources: "Ticket-Tracking Link Sources",
	ticket_sharing_link_triggers: "Ticket-Tracking Link Triggers",
	on_agent_assignment: "On Agent Assignment",
	on_sla_breach: "On SLA Breach",
	on_ticket_closure: "On Ticket Closure",
	brand: "Brand",
	brand_display_name: "Brand Display Name",
	brand_website: "Brand Website",
	agent_display_name: "Agent name as will appear to users",
	agent_logo_url: "Agent logo URL, as a publicly available URL",
	privacy_policy: `Privacy policy URL, starting with "https://"`,
	agent_welcome_message: "Agent Welcome Message to Greet Users",
	place_ids:
		"Comma-separated Place IDs, eg, ChIJgUbEo8cfqokR5lP9_Wh_DaM,IhoSGAoUChIJ0U6OoscfqokR6P225pApu2UQDQ",
	place_id_disclaimer:
		"Add Place Ids for all your business locations where you need the chat button. Find your Place Ids on the Map at: ",
	google_business_messaging_disclaimer:
		"NB:-> Once you successfully add these details, expand the integration on the table and initiate agent verification, then once verified, initiate the location verification. Once location is also verified, you should see the chat button on Google",
	verify: "Verify",
	launch_agent_verification: "Launch Agent Verification",
	launch_location_verification: "Launch Location Verification",
	agent_verified: "Agent Verified",
	location_verified: "Location Verified",
	contact_email: "Contact Email",
	none_select_text: "None",
	call_to_action: "Call to action",
	quick_reply: "Quick reply",
	marketing: "Marketing",
	utility: "Utility",
	authentication: "Authentication",
	english: "English",
	swahili: "Swahili",
	create_buttons_label:
		"Create buttons for customers to take action on your messages",
	add_button_label: "Add Button",
	visit_website: "Visit website",
	call_phone: "Call phone",
	webchat_prompt_message: "Webchat Message Without a Greeting",
	webchat_prompt_message_placeholder:
		"Webchat Message without a Greeting, eg, Welcome to {{ Organization }}. We are here to help`",
	no_permission_to_access_ticket:
		"You do not have permission to access this ticket",
	ticket_assignment_email_notification:
		"Email Notification on Ticket Assignment",
	ticket_closure_email_notification: "Email Notification on Ticket Closure",
	bot_builder: "Flow Builder",
	bot_flows: "Flows",
	building_blocks: "Building Blocks",
	hours: "Hours",
	minutes: "Minutes",
	facebook_messenger: "Facebook Messenger",
	instagram_messenger: "Instagram Messenger",
	add_query: "Add Query",
	edit_query: "Edit Query",
	view_query: "View Query",
	query: "Query",
	queries: "Queries",
	csv: "CSV",
	json: "JSON",
	view_flow: "View Flow",
	edit_flow: "Edit Flow",
	flow: "Flow",
	new_flow: "New Flow",
	group: "Group",
	groups: "Groups",
	edit_group: "Edit Group",
	select_group: "Select Group (Optional)",
	select_keywords: "Add Key Words (Optional)",
	keywords: "Key Words",
	auto_create_leads: "Auto-Create Leads",
	integrations_with_auto_create_leads: "Integrations with Lead Auto-Creation",
	source_ticket: "Source Ticket",
	calls: "Calls",
	chatdesk: "Chatdesk",
	nia: "Nia",
	billing: "Billing",
	edit_form: "Edit {form_title}",
	unlink_catalog: "Unlink Catalog",
	closed_session: "Closed Session",
	active_session: "Active Session",
	reset: "Reset",
	error_saving_flow: "Error saving flow",
	flow_saved_successfully: "Flow saved successfully",
	connect: "Connect",
	connect_catalog: "Connect Catalog",
	catalog_connected: "Catalog Connected",
	delete_flow: "Delete Flow",
	close_ticket: "Close Ticket",
	confirm_close_ticket: "Please confirm closure of this ticket",
	template_created_successfully: "Template created successfully",
	open_link: "Open Link",
	total_calls_offered: "Total Calls Offered",
	total_calls_abandoned: "Total Calls Abandoned",
	total_calls_answered: "Total Calls Answered",
	wait_time: "Wait Time",
	job_title: "Job Title",
	job_department: "Job Department",
	nickname: "Nickname",
	other_name: "Other Name",
	prefix: "Prefix",
	suffix: "Suffix",
	url: "URL",
	birthday: "Birthday",
	street: "Street",
	address: "Address",
	others: "Others",
	titles: "Titles",
	contact_disclaimer:
		"First and last name required. Phone number should begin with country code, eg, +254...",
	personal_details: "Personal Details",
	add_custom_field: "Add Custom Field",
	field_name: "Field Name",
	contact_group: "Contact Group",
	add_contacts_to_group: "Add Contacts to Group",
	edit_contact_group: "Edit Contact Group",
	creator: "Creator",
	unknown: "Unknown",
	contact_tags: "Contact Tags",
	contact_labels: "Contact Labels",
	edit_label: "Edit Label",
	autosend_csat_on_ticket_closure: "Auto-Send CSAT on Ticket Closure",
	send_csat: "Send CSAT",
	confirm_send_csat: "Please confirm sending CSAT",
	email_template: "Email Template",
	select_integration: "Select Integration",
	autoappend_agent_name: "Auto-Append Signature to Messages",
	enter_number_country_code:
		"Please enter number(s) with country code (ex: +254...)",
	ticket_assignment_email_notification_to_whole_group:
		"Email Notification on Ticket Assignment to Group Members",
	ticket_closure_message_to_client: "Ticket Closure CSAT Message to Client",
	integrations_with_ticket_closure_message:
		"Integrations with Custom Ticket Closure CSAT Message",
	ticket_link_sharable_message_to_client:
		"Ticket Tracking Link Message to Client",
	integrations_with_ticket_link_sharable:
		"Integrations with Sharable Ticket Tracking Link Message",
	resolved_on: "Resolved On",
	first_time_marked_in_progress: "Marked In Progress",
	issue_resolved: "Issue Resolved",
	agent_rating: "Agent Rating",
	company_rating: "Company Rating",
	nps_score: "Net Promoter Score",
	use_full_agent_name_or_initials:
		"Use Full Agent Name or Initials for Non-Email Sources",
	full_name: "Full Name",
	initials: "Initials",
	email_signature: "Signature for Email Messages",
	save_email_signature: "Save Email Signature",
	email_signature_disclaimer:
		"Valid email signature placeholders are: {{user.first_name}} {{user.last_name}} {{user.phone}} {{user.email}} {{user.email_signature}} {{company.name}}",
	insert_image: "Insert Image",
	apply_filters: "Apply Filters",
	on_ticket_creation: "On Ticket Creation",
	serial_number: "Serial Number",
	cc: "CC",
	reply_all: "Reply All",
	auto_send_out_of_office_response: "Auto-Send Out of Office Message",
	integrations_with_out_of_office_response:
		"Integration with Out of Office Response",
};
export default en;
