import { SET_AVAYA_ONLINE_AGENTS } from "../actions/actions";

export default function AvayaOnlineAgents(state: any = null, action: any) {
  switch (action.type) {
    case SET_AVAYA_ONLINE_AGENTS:
      return action.payload;
    default:
      return state;
  }
}
