import { CallProgress } from "../../../types/DataTypes";

//@ts-ignore
import AvayaController from "../avaya-controller";

export interface IpoRequestError {
	error: string;
}

export interface IpoLoginRequestSuccess {
	sessionId: string;
	extension: string;
	username: string;
	wssServer: string;
}

export type IpoCallDirection = "INCOMING" | "OUTGOING" | "";
export type IpoCallType = "incoming" | "outgoing" | "missed" | "both" | "overview";

export enum IpoSessionState {
	Initial = "Initial",
	Establishing = "Establishing",
	Delivered = "Delivered",
	Disconnected = "Disconnected",
	Failed = "Failed",
}

export interface IpoCallSessionData {
	name?: string;
	number?: string;
	recipient?: string;
	callAppearanceNo?: number;
	direction?: IpoCallDirection;
	connectionId?: string;
	isHeld?: boolean;
	isMuted?: boolean;
	isConsultationCall?: boolean;
	isAnswered?: boolean;
	isFailed?: boolean;
	isConference?: boolean;
	isRinging?: boolean;
	callDuration?: string;
	startTime?: string|null;
	agents?: any[];
	state?: IpoSessionState| string | null;
}

export interface IpoActiveCallState {
	call: IpoCallSessionData | null;
	notify: boolean;
	direction: IpoCallDirection;
	sessionState: IpoSessionState| string | null;
}

export interface IpoActiveCallStateAction {
	type: string;
	payload: IpoActiveCallState;
}
export interface IpoActiveCallStateReducer {
	(state: IpoActiveCallState, action: IpoActiveCallStateAction): IpoActiveCallState;
}

export interface IpoCallStatus {
	progress: CallProgress;
	callDirection: IpoCallDirection;
}

export interface IpoPhoneState {
	activeCall: boolean;
	establishingCall?: IpoCallSessionData | null;
	callDuration?: string;
	startTime?: string|null;
	agents?: any[];
	isConnected: boolean;
	callStatus: IpoCallStatus;
	phone: AvayaController;
	hold: boolean;
	mute: boolean;
	orgId?: string;
	handleEndCall: () => void;
	handleAnswerCall: () => void;
	handleRejectCall: () => void;
	handleConnect: () => void;
	handleDisconnect: () => void;
	handleHold: () => void;
	handleTransfer: (number: string) => Promise<void>;
	handleEndConsultation?: () => void;
	handleTransferConsultation?: () => void;
}

export interface RedirectResType {
	phoneNumber: string;
	customerNo: string|null;
	link: string;
}